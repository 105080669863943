// import React from 'react';
// import { Modal } from 'flowbite-react';
// import Button from '@/components/UI/Button';

// interface ExistingEmailModalProps {
//   show: boolean;
//   handleClose: () => void;
// }

// const ExistingEmailModal = ({ show, handleClose }: ExistingEmailModalProps) => {
//   const handleCloseAndClear = () => {
//     localStorage.clear();
//     handleClose();
//     window.location.reload();
//   };

//   return (
//     <Modal show={show} onClose={handleClose} position="center" size="2xl">
//       <Modal.Body className="p-4">
//         <section className="p-4 flex flex-col gap-7 items-center">
//           <p className="text-lg">
//             <h6>This email is already registered as an investor. Please log in using a different email.</h6>
//           </p>
//           <div className="flex gap-4 w-full">
//             <Button secondary rounded onClick={handleCloseAndClear} classNames="w-full">
//               Close
//             </Button>
//           </div>
//         </section>
//       </Modal.Body>
//     </Modal>
//   );
// };

// export default ExistingEmailModal;



import React from 'react';
import { Modal } from 'flowbite-react';
import Button from '@/components/UI/Button';

interface ExistingEmailModalProps {
  show: boolean;
  handleClose: () => void;
}

const ExistingEmailModal = ({ show, handleClose }: ExistingEmailModalProps) => {
  const handleCloseAndClear = () => {
    // localStorage.clear();
    handleClose();
    // window.location.reload();
  };

  return (
    <Modal show={show} onClose={handleClose} position="center" size="2xl">
      <Modal.Body className="p-4">
        <section className="p-4 flex flex-col gap-7 items-center">
          <p className="text-lg">
            <h6>We are currently working on some major upgrades. Stay tuned!</h6>
          </p>
          <div className="flex gap-4 w-full">
            <Button secondary rounded onClick={handleCloseAndClear} classNames="w-full">
              Close
            </Button>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default ExistingEmailModal;
