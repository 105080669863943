// import { useContext, useEffect, useState, KeyboardEvent } from "react";
// import toast from "react-hot-toast";
// import { useNavigate } from "react-router-dom";
// import Web3 from "web3";
// import { jwtDecode } from "jwt-decode";
// import { z } from "zod";
// import Cookies from "js-cookie";

// import ExistingEmailModal from "@/components/ExistingEmailModal";
// import Button from "@/components/UI/Button";
// import { ENDPOINT, ROUTES } from "@/constants";
// import { AuthContext } from "@/services/AuthContext";
// import { useWeb3Auth } from "@/services/Web3AuthService";
// import { fetchUser } from "@/services/api";
// import { http } from "@/services/http";
// import useStore from "@/store/useStore";
// import { WALLET_ADAPTERS } from "@web3auth/base";

// // Create a zod schema for email validation
// const emailSchema = z.string().email("Please enter a valid email address");

// const Login = () => {
//   const [isInitializing, setIsInitializing] = useState<boolean>(false);
//   const [initialized, setInitialized] = useState<boolean>(false);
//   const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
//   const [showExistingEmailModal, setShowExistingEmailModal] = useState<boolean>(false);
//   // eslint-disable-next-line
//   const [errorMessage, setErrorMessage] = useState<string>("");
//   const [email, setEmail] = useState<string>("");
//   const [emailError, setEmailError] = useState<string | null>(null); // State for email validation error
//   const { login } = useContext(AuthContext);
//   const navigate = useNavigate();
//   const { setUser, setCompany } = useStore();
//   const { web3auth, initializeWeb3Auth } = useWeb3Auth(); // Use the hook

//   useEffect(() => {
//     const authToken = localStorage.getItem("authToken");
//     const web3AuthToken = localStorage.getItem("web3AuthToken");
//     const savedEmail = Cookies.get("savedEmail");

//     console.log("Email Stored in Cookies: ", savedEmail);

//     if (authToken && web3AuthToken) {
//       setIsLoggedIn(true);
//     }

//     if (savedEmail) {
//       setEmail(savedEmail);
//     }
//   }, []);

//   const handleWeb3AuthLogin = async (email: string) => {
//     // Validate the email before proceeding
//     const validation = emailSchema.safeParse(email);
//     if (!validation.success) {
//       setEmailError(validation.error.issues[0].message);
//       return;
//     }
//     setEmailError(null); // Clear error if email is valid

//     if (isInitializing) return;

//     setIsInitializing(true);
//     try {
//       if (!initialized) {
//         await initializeWeb3Auth();
//         setInitialized(true);
//       }

//       /**
//        * Change: 10 Oct 2024 5:00 PM (IST)
//        * Change WALLET_ADAPTERS.OPENLOGIN to WALLET_ADAPTERS.AUTH,
//        * Reson: The OPENLOGIN is removed
//        */
//       await web3auth.connectTo(WALLET_ADAPTERS.AUTH, {
//         loginProvider: "email_passwordless",
//         extraLoginOptions: {
//           login_hint: email,
//         },
//       });

//       const userInfo = await web3auth.getUserInfo();
//       console.log("UserInfo From Web3Auth: ", userInfo);

//       if (!web3auth.provider) {
//         throw new Error("Web3Auth provider is not available");
//       }

//       const web3 = new Web3(web3auth.provider as any);
//       const accounts = await web3.eth.getAccounts();
//       if (accounts.length === 0) throw new Error("No account found");
//       const publicAddress = accounts[0];
//       console.log("Public Address from Web3Auth: ", publicAddress);

//       const emailFromUserInfo = userInfo.email;
//       const idToken = (await web3auth.authenticateUser()).idToken;
//       console.log("Id Token from Web3Auth: ", idToken);

//       const response = await http.post(
//         ENDPOINT.WEB3_AUTH,
//         { email: emailFromUserInfo, publicAddress },
//         {
//           headers: {
//             Authorization: `Bearer ${idToken}`,
//           },
//         }
//       );
//       console.log("Response From Backend: ", response);

//       const authToken = response.data.data.authToken;
//       console.log("Auth Token: ", authToken);
//       login(authToken, idToken, web3auth.provider);

//       if (typeof authToken !== "string") {
//         throw new Error("Invalid authToken type");
//       }

//       const decoded: any = jwtDecode(authToken);
//       const userId = decoded.userId;

//       const userResponse: any = await fetchUser(userId);
//       if (userResponse.code === 200) {
//         const { companyDetails, ...updatedUserData } = userResponse.data;
//         setUser(updatedUserData);
//         setCompany(companyDetails);

//         const isProfileExisting = updatedUserData.isProfileExisting;

//         if (!isProfileExisting) {
//           navigate(ROUTES.PROFILE_SETUP);
//         } else {
//           navigate(ROUTES.DASHBOARD);
//         }

//         // Save email to cookies and tokens to localStorage
//         Cookies.set("savedEmail", email, { expires: 365 }); // Cookie expires in 1 year
//         console.log("Set email in Cookies for 1 year: ");
//         localStorage.setItem("authToken", authToken);
//         localStorage.setItem("web3AuthToken", idToken);
//       } else {
//         throw new Error("Failed to fetch user data");
//       }
//     } catch (error: any) {
//       if (error.status === 409) {
//         setErrorMessage(error.message);
//         setShowExistingEmailModal(true);
//       } else {
//         toast.error("Login failed. Please try again.");
//       }
//       console.error("Web3Auth login error:", error);
//     } finally {
//       setIsInitializing(false);
//     }
//   };

//   const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
//     if (event.key === "Enter") {
//       handleWeb3AuthLogin(email);
//     }
//   };

//   return (
//     <>
//       {!isLoggedIn && (
//         <div className="mt-6 w-[218px] text-center">
//           <input
//             type="email"
//             className="w-full text-center border border-1 border-[#DFDFDF] text-[0.75rem]"
//             placeholder="Enter your email"
//             value={email}
//             onChange={(e) => {
//               setEmail(e.target.value);
//               setEmailError(null); // Clear error when user starts typing
//             }}
//             onKeyDown={handleKeyDown}
//           />
//           {emailError && <p className="text-red-500 text-xs mt-2">{emailError}</p>} {/* Display email error */}
//           <Button classNames="mt-5 mr-[4rem]" onClick={() => handleWeb3AuthLogin(email)} secondary rounded>
//             Log in
//           </Button>
//         </div>
//       )}
//       <ExistingEmailModal
//         show={showExistingEmailModal}
//         handleClose={() => {
//           Cookies.remove("savedEmail");
//           localStorage.removeItem("authToken");
//           localStorage.removeItem("web3AuthToken");
//           setShowExistingEmailModal(false);
//           window.location.reload();
//         }}
//       />
//     </>
//   );
// };

// export default Login;



import { useContext, useEffect, useState, KeyboardEvent } from "react";
import Cookies from "js-cookie";
import ExistingEmailModal from "@/components/ExistingEmailModal";
import Button from "@/components/UI/Button";

const Login = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [showExistingEmailModal, setShowExistingEmailModal] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<string | null>(null);

  useEffect(() => {
    const authToken = localStorage.getItem("authToken");
    const savedEmail = Cookies.get("savedEmail");

    if (authToken) {
      setIsLoggedIn(true);
    }

    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  const handleButtonClick = () => {
    // Validate email before showing the modal
    // if (!email) {
    //   setEmailError("Email is required.");
    //   return;
    // }

    // setEmailError(null); // Clear any previous error
    setShowExistingEmailModal(true); // Show the modal
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleButtonClick(); // Trigger modal on Enter key
    }
  };

  return (
    <>
      {!isLoggedIn && (
        <div className="mt-6 w-[218px] text-center">
          <input
            type="email"
            className="w-full text-center border border-1 border-[#DFDFDF] text-[0.75rem]"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError(null); // Clear error when user starts typing
            }}
            onKeyDown={handleKeyDown}
          />
          {emailError && <p className="text-red-500 text-xs mt-2">{emailError}</p>} {/* Display email error */}
          <Button classNames="mt-5 mr-[4rem]" onClick={handleButtonClick} secondary rounded>
            Log in
          </Button>
        </div>
      )}
      <ExistingEmailModal
        show={showExistingEmailModal}
        handleClose={() => {
          Cookies.remove("savedEmail");
          localStorage.removeItem("authToken");
          localStorage.removeItem("web3AuthToken");
          setShowExistingEmailModal(false);
        }}
      />
    </>
  );
};

export default Login;

